import React, {useState} from 'react';
import TableModel from "../../../page/room/table-model/TableModel";
import './table-template-menu.css';
import {useSelector} from "react-redux";
import {selectVenueTableTemplates} from "../../../slice/tableSlice";
import CreateNewTableTemplate from "../../create-table-template/CreateNewTableTemplate";
import CenteredPopup from "../../centered-popup/CenteredPopup";
import {selectActiveRoom} from "../../../slice/roomSlice";
import BottomPagePopoutMenu from "../../bottom-page-popout/BottomPagePopout";
import RoomTable from "../../room-table/RoomTable";

export default function TableTemplateMenu() {
    const [creatingNewTable, setCreatingNewTable] = useState(false);

    const room = useSelector(selectActiveRoom);

    const uniqueTables = useSelector(selectVenueTableTemplates);

    return (<>
            <CenteredPopup showPopup={creatingNewTable} closePopup={setCreatingNewTable}>
                <CreateNewTableTemplate room_id={room.id}
                                        venue_id={room.venue_id}
                                        isVenueTemplate={false}
                                        onClose={_ => setCreatingNewTable(false)}/>
            </CenteredPopup>
            <div className='table-template-menu'>
                <div className='model-list'>
                    {uniqueTables.map(t => <div key={t.id} draggable={true}
                                                className='table-3d-model-wrapper'
                                                onDragStart={e => {
                                                    e.dataTransfer.setData('application/tableDragCreate', JSON.stringify([t]))
                                                }}>
                        <div style={{
                            position: 'relative',
                            marginLeft: '40px',
                            width: '50px',
                            transform: 'scale(0.5) translateY(50%)'
                        }}>
                            <RoomTable table={{...t, position: {x: 0, y: 0}, rotation: 0}}
                                       pointer_events={false}
                                       key={`table_preview-${t.id}`}
                                       room={room}
                            />
                        </div>
                        <div className='table-info'>
                            <h3>{t.name}</h3>
                            <p>({t.width}, {t.length})m dimensions</p>
                            <p>{t.max_seating} max</p>
                        </div>
                    </div>)}
                </div>
                <div className='create-new-table-background'>
                    <p onClick={_ => setCreatingNewTable(true)} className='create-new-table-pop-btn'>+ opret nyt
                        bord</p>
                </div>
            </div>
        </>
    )

}
import React, {useCallback} from "react";
import './mouse-event-handler.css'

export default function MouseEventHandler({mouseMove, mouseDown, mouseUp, mouseClick, children, killAllOtherEvents, ...props}) {
    const mouseUpProxy = useCallback(event => {
        if (killAllOtherEvents) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (mouseUp) {
            mouseUp(event)
        }
    }, [mouseUp, killAllOtherEvents])
    const mouseDownProxy = useCallback(event => {
        if (killAllOtherEvents) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (mouseDown) {
            mouseDown(event)
        }
    }, [mouseDown, killAllOtherEvents])
    const mouseMoveProxy = useCallback(event => {
        if (killAllOtherEvents) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (mouseMove) {
            mouseMove(event)
        }
    }, [mouseMove, killAllOtherEvents])
    const mouseClickProxy = useCallback(event => {
        if (killAllOtherEvents) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (mouseClick) {
            mouseClick(event)
        }
    }, [mouseClick, killAllOtherEvents])
    return <div
        onMouseUp={mouseUpProxy}
        onMouseDown={mouseDownProxy}
        onMouseMove={mouseMoveProxy}
        onClick={mouseClickProxy}
        onDrag={_ => console.log('hellooo')}
        {...props}
        className='mouse-event-handler'>
        {children}
    </div>
}

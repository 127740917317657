import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {TableApi} from "../api";
import useElements, {elementTypes} from "../component/room/element/useElements";

const initialState = {
    tables: {},
    table_templates: [],
    loading: false,
    editing_table: {},
    editing_table_ids: null,
    hovering_table_id: null,
    dragging_table_id: null,
    drag_created_table: null,
    selected_table_ids: [],
    selected_element_ids: [],
    hovering_table_override: {},
    history_actions: [],
}

export const doCreateTable = createAsyncThunk('table/createTable', async ({
                                                                              room_id = null,
                                                                              venue_id = null,
                                                                              name,
                                                                              max_seating,
                                                                              width,
                                                                              length,
                                                                              type,
                                                                              position,
                                                                              rotation,
                                                                              is_venue_template = false,
                                                                          }) => {
    // todo: Call this afterwards: dispatch(doFetchTableTemplates({room_id: room.id, venue_id: null}))
    return await TableApi.create(room_id, venue_id, name, max_seating, width, length, type, position, rotation, is_venue_template)
})

export const doSeatGuests = createAsyncThunk('table/doSeatGuest', async ({table_id, guest_ids}) => {
    return TableApi.seat_guests(table_id, guest_ids)
})

export const doSeatGuestWithCount = createAsyncThunk('table/doSeatGuestWithCount', async ({
                                                                                              table_id,
                                                                                              guest_id,
                                                                                              count = null
                                                                                          }) => {
    return TableApi.seat_guests_with_count(table_id, guest_id, count)
})

export const doUnseatGuests = createAsyncThunk('table/doSeatGuest', async ({guest_ids}) => {
    return TableApi.unseat_guests(guest_ids)
})

export const doFetchRoomTables = createAsyncThunk('table/fetchRoomTables', async (room_id) => {
    return await TableApi.list_tables_for_room(room_id)
})

export const doFetchTableTemplates = createAsyncThunk('table/fetchTableTemplates', async ({room_id, venue_id}) => {
    return await TableApi.list_table_templates(room_id, venue_id)
})

export const doUpdateTable = createAsyncThunk('table/update', async ({table_id, values}) => {
    return TableApi.update_table(table_id, values)
})

export const doDeleteTable = createAsyncThunk('table/delete', async (table_id) => {
    return new Promise(async res => {
        await TableApi.delete_table(table_id)
        res(table_id)
    })
})

export const doSetTableLocally = createAsyncThunk('table/doSetTableLocally', async ({
                                                                                        table_id,
                                                                                        values,
                                                                                        undoable = true
                                                                                    }) => {
    return {table_id, values, undoable}
})

export const doSetHoveringTableOverride = createAsyncThunk('table/doSetHoveringTableOverride', async (values) => {
    return values
})

export const doSetMultiplePositionsLocally = createAsyncThunk('table/doSetMultiplePositionsLocally', async (tables) => {
    return tables
})

export const doSetEditingTable = createAsyncThunk('table/doSetEditingTable', async (table_ids) => {
    return table_ids
})

export const doSetStopHoveringTable = createAsyncThunk('table/doSetStopHoveringTable', async (table_id) => {
    return table_id
})
export const doSetHoveringTable = createAsyncThunk('table/doSetHoveringTable', async (table_id) => {
    return table_id
})

export const doSetDraggingTable = createAsyncThunk('table/doSetDraggingTable', async (table_id) => {
    return table_id
})

export const doSetStopDraggingTable = createAsyncThunk('table/doSetStopDraggingTable', async (table_id) => {
    return table_id
})

export const doSetSelectedTablesAndElements = createAsyncThunk('table/doSetSelectedTables', async ({
                                                                                                       table_ids,
                                                                                                       element_ids
                                                                                                   }) => {
    return {table_ids, element_ids}
})

export const doSetTableAsClean = createAsyncThunk('table/doSetTableAsClean', async ({table_id, values}) => {
    return {table_id, values}
})

export const createUndoableAction = createAsyncThunk('table/createUndoableAction', async (tables) => {
    return tables
})

export const doUndoAction = createAsyncThunk('table/undoAction', async () => {
    return null;
})

export const setLoading = createAsyncThunk('table/setLoading', async (loading) => {
    return loading
})

export const thunkUndoAction = async (dispatch, getState) => {
    const state = getState();
    if (state.table.history_actions.length === 0) {
        return;
    }
    if (state.table.loading) return;

    const last_action = state.table.history_actions[0]
    dispatch(doUndoAction())

    Promise.all(last_action.map(t => dispatch(doSetTableLocally({table_id: t.id, values: t.values, undoable: false})))).then(() => {
        dispatch(thunkSaveDirtyTables)
    })
}

export const thunkSaveDirtyTables = (dispatch, getState) => {
    const state = getState()
    if (state.table.loading) return;
    dispatch(setLoading(true))
    const dirty_tables = Object.values(state.table.tables).filter(t => t.dirty);

    // console.log('thunk save dirty', dirty_tables)
    const undoable = dirty_tables.map(t => ({
        id: t.id,
        name: t.name,
        values: t.cleanValues
    })).filter(t => t.values !== null)
    if (undoable.length > 0) {
        // console.log('Saving an action as undoable', undoable)
        dispatch(createUndoableAction(undoable))
    }
    const toSave = [];

    dirty_tables.forEach(t => {
        let dirty = {...t.dirty}
        if (t.dirty.position) {
            dirty = {
                ...dirty, position: {
                    x: Math.floor(dirty.position.x),
                    y: Math.floor(dirty.position.y)
                }
            }
        }
        toSave.push({...t, ...dirty})
    })
    return TableApi.batch_update_tables(toSave).then(updated_tables => {
        updated_tables.forEach(t => {
            dispatch(doSetTableAsClean({table_id: t.id, values: t}))
        })
        dispatch(setLoading(false))
    })
}


const tableSlice = createSlice({
    name: 'table',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(doCreateTable.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doCreateTable.fulfilled, (state, action) => {
                state.tables = {...state.tables, [action.payload.id]: action.payload}
                state.loading = false
            })

            .addCase(doSeatGuests.fulfilled, (state, action) => {
                const updated_tables = action.payload
                Object.keys(updated_tables).forEach(seated_table_id => {
                    if (!state.tables[seated_table_id]) return;
                    state.tables[seated_table_id] = {
                        ...state.tables[seated_table_id],
                        seats: updated_tables[seated_table_id]
                    }
                })
            })

            .addCase(doSeatGuestWithCount.fulfilled, (state, action) => {
                const updated_tables = action.payload
                Object.keys(updated_tables).forEach(seated_table_id => {
                    if (!state.tables[seated_table_id]) return;
                    state.tables[seated_table_id] = {
                        ...state.tables[seated_table_id],
                        seats: updated_tables[seated_table_id]
                    }
                })
            })

            .addCase(doFetchRoomTables.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doFetchRoomTables.fulfilled, (state, action) => {
                const as_dict = {}
                action.payload.forEach(t => as_dict[t.id] = t)
                state.tables = as_dict
                state.loading = false
            })

            .addCase(doFetchTableTemplates.fulfilled, (state, action) => {
                state.table_templates = action.payload
            })

            .addCase(doUpdateTable.fulfilled, (state, action) => {
                const updated = action.payload
                state.tables[updated.id] = updated
                state.table_templates = state.table_templates.map(t => ({...t, ...(t.id === updated.id && updated)}))
            })

            .addCase(doDeleteTable.pending, (state, action) => {
                state.loading = true
            })
            .addCase(doDeleteTable.fulfilled, (state, action) => {
                const deleted_id = action.payload
                delete state.tables[deleted_id]
                state.loading = false

                if (state.selected_table_ids && state.selected_table_ids.includes(deleted_id)) {
                    state.selected_table_ids = state.selected_table_ids.filter(id => id !== deleted_id)
                }

                if (state.editing_table_ids && state.editing_table_ids.includes(deleted_id)) {
                    state.editing_table_ids = state.editing_table_ids.filter(id => id !== deleted_id)
                }
            })

            .addCase(doSetTableAsClean.fulfilled, (state, action) => {
                const {table_id, values} = action.payload
                state.tables[table_id] = {...state.tables[table_id], ...values, dirty: null, cleanValues: null}
            })

            .addCase(doSetTableLocally.fulfilled, (state, action) => {
                const {table_id, values: updatedValues, undoable} = action.payload

                const existing = state.tables[table_id]
                const dirty = {...(existing.dirty || {}), ...updatedValues}
                const existingClean = existing.cleanValues || {}
                let cleanValues = {};
                if (undoable) {
                    // save the clean values - if we have an earlier clean value, use that
                    Object.keys(dirty).forEach((key) => {
                        if (existingClean[key] !== undefined) {
                            cleanValues[key] = existingClean[key]
                            return;
                        }
                        cleanValues[key] = existing[key]
                    })
                }

                state.tables[table_id] = {
                    ...existing, ...updatedValues,
                    dirty,
                    cleanValues: undoable ? cleanValues : null
                }
            })

            .addCase(doSetHoveringTableOverride.fulfilled, (state, action) => {
                state.hovering_table_override = action.payload
            })

            .addCase(doSetMultiplePositionsLocally.fulfilled, (state, action) => {
                const tables = action.payload;
                tables.forEach(t => {
                    const existing = state.tables[t.id]
                    const dirty = {...(existing.dirty || {}), position: t.position}

                    state.tables[t.id] = {...existing, position: t.position, dirty}
                })
            })

            .addCase(doSetEditingTable.fulfilled, (state, action) => {
                const table_ids = action.payload
                state.editing_table_ids = table_ids
            })

            .addCase(doSetHoveringTable.fulfilled, (state, action) => {
                state.hovering_table_id = action.payload
            })
            .addCase(doSetStopHoveringTable.fulfilled, (state, action) => {
                if (state.hovering_table_id === action.payload) {
                    state.hovering_table_id = null
                }
            })

            .addCase(doSetDraggingTable.fulfilled, (state, action) => {
                state.dragging_table_id = action.payload
            })
            .addCase(doSetStopDraggingTable.fulfilled, (state, action) => {
                if (state.dragging_table_id === action.payload) {
                    state.dragging_table_id = null
                }
            })

            .addCase(doSetSelectedTablesAndElements.fulfilled, (state, action) => {
                const {table_ids, element_ids} = action.payload
                if (state.selected_table_ids === table_ids && state.selected_element_ids === element_ids) return;

                state.selected_table_ids = table_ids
                state.selected_element_ids = element_ids
            })

            .addCase(createUndoableAction.fulfilled, (state, action) => {
                state.history_actions = [action.payload, ...state.history_actions]
            })
            .addCase(doUndoAction.fulfilled, (state, action) => {
                state.history_actions = state.history_actions.slice(1)
            })

            .addCase(setLoading.fulfilled, (state, action) => {
                state.loading = action.payload
            })
    }
})

export default tableSlice.reducer

export const allTablesAsDict = state => state.table.tables

export const selectAllTables = createSelector([allTablesAsDict], (tables) => {
    return [...Object.values(tables).sort((a, b) => parseInt(a.name) - parseInt(b.name))]
})

export const selectVenueTableTemplates = state => {
    return state.table.table_templates
}

export const selectAllSeated = state => {
    let totalSeatedByGuestId = {}
    if (!state.table.tables) return totalSeatedByGuestId
    Object.values(state.table.tables).forEach(t => {
        Object.keys(t.seats).forEach(guest_id => {
            totalSeatedByGuestId[guest_id] = (totalSeatedByGuestId[guest_id] || 0) + t.seats[guest_id]
        })
    })
    return totalSeatedByGuestId
}

export const selectTableLoading = state => {
    return state.table.loading
}

export const selectTableById = table_id => state => {
    if (!table_id) return null;
    return state.table.tables[table_id]
}

export const selectEditingTableIds = state => {
    return state.table.editing_table_ids
}

export const selectEditingTables = state => {
    if (!state.table.editing_table_ids) return []
    return state.table.editing_table_ids.map(id => state.table.tables[id])
}

export const selectHoveringTableId = state => {
    return state.table.hovering_table_id
}

export const selectHoveringTableOverride = state => {
    return state.table.hovering_table_override
}

export const selectDraggingTableId = state => {
    return state.table.dragging_table_id
}

export const selectSelectedTableIds = state => state.table.selected_table_ids;
export const selectSelectedElementIds = state => state.table.selected_element_ids;

const _tables = state => state.table.tables;
const _elements = state => state.element.elements;

export const selectSelectedTables = createSelector([selectSelectedTableIds, _tables], (selected_ids, tables) => {
    if (!selected_ids) return []
    return selected_ids.map(id => tables[id])
})

export const selectSelectedElements = createSelector([selectSelectedElementIds, _elements], (selected_ids, elements) => {
    if (!selected_ids) return []
    return selected_ids.map(id => elements[id])
});

export const selectActiveRoom = state => {
    return state.room.rooms[state.room.active_room_id]
}

export const selectHistoryActions = state => state.table.history_actions;

export const selectSelectedTableRect = createSelector(
    [_tables, _elements, selectSelectedTableIds, selectSelectedElementIds, selectActiveRoom],
    (tables, elements, selectedTableIds, selectedElementIds, room) => {
        if (selectedTableIds.length === 0 && selectedElementIds.length === 0) return null;
        const selectedTables = selectedTableIds.map(id => tables[id]);
        const selectedElements = selectedElementIds.map(id => elements[id]);

        const xs = [];
        const ys = [];

        selectedTables.forEach(t => {
            const width = ((t.width + t.seat_size * 2) * room.pixel_pr_meter) / 2
            const length = (t.length + (t.type === 'Round' ? t.seat_size * 2 : 0)) * room.pixel_pr_meter / 2

            xs.push(...[t.position.x - width, t.position.x + width])
            ys.push(...[t.position.y - length, t.position.y + length])
        })
        selectedElements.forEach(e => {
            const t = elementTypes[e.type]
            // console.log(e, t.getBoundingRect(e, room.pixel_pr_meter))
            const {left, top, width, height} = t.getBoundingRect(e, room.pixel_pr_meter)
            xs.push(...[left, left + width])
            ys.push(...[top, top + height])
        })

        const left = Math.min(...xs)
        const top = Math.min(...ys)

        const right = Math.max(...xs)
        const bottom = Math.max(...ys)

        const width = right - left;
        const height = bottom - top;
        return {
            left, top, width, height, alignment: {
                x: {
                    start: left,
                    center: left + width / 2,
                    end: left + width
                },
                y: {
                    start: top,
                    center: top + height / 2,
                    end: top + height
                }
            }
        }
    })

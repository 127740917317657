import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import InputField from "../../../component/input-field/InputField";

import {
    doDeleteTable,
    doSetEditingTable,
    doSetTableLocally,
    selectSelectedTableRect, thunkSaveDirtyTables
} from "../../../slice/tableSlice";
import debounce from "lodash.debounce";
import './table-edit-window.css';
import {doSetContextMenu, selectTableContextMenu} from "../../../slice/elementSlice";
import binImg from '../../../assets/bin.png';


export default function TableEditWindow({
                                            editingVenue,
                                            editingEvent,
                                            showWidth = false,
                                            showLength = false,
                                        }) {
    const [changed, setChanged] = useState({});

    const dispatch = useDispatch()

    const editingTables = useSelector(selectTableContextMenu);
    const selectedTablesRect = useSelector(selectSelectedTableRect);

    const zoom = useSelector(state => state.room.zoom);

    useEffect(() => {
        setChanged({})
    }, [editingTables]);

    const deleteSelectedTables = useCallback(() => {
        if (window.confirm(`Er du sikker på at du vil slette ${editingTables.length} borde?`)) {
            dispatch(doSetContextMenu(null));
            editingTables.forEach(t => dispatch(doDeleteTable(t.id)));
        }
    }, [editingTables, dispatch]);

    const updateTableBackend = useCallback((changes) => {
        dispatch(thunkSaveDirtyTables)
    }, [dispatch]);

    const debouncedUpdateTable = useMemo(() => debounce(updateTableBackend, 800), [updateTableBackend]);

    useEffect(() => {
        if (Object.keys(changed).length === 0) return;

        debouncedUpdateTable(changed)
    }, [changed, debouncedUpdateTable]);

    const setTable = useCallback((id, values) => {
        setChanged(p => ({...p, [id]: {...p[id], ...values}}));
        dispatch(doSetTableLocally({table_id: id, values}))
    }, [debouncedUpdateTable, dispatch]);

    const closeEdit = useCallback(() => {
        dispatch(doSetEditingTable(null));
        dispatch(doSetContextMenu(null));
    }, [dispatch]);

    const similarValues = useMemo(() => {
        if (!editingTables) return {};
        const res = {
            name: null,
            type: null,
            active_seats: null,
            max_seating: null,
            width: null,
            length: null,
            rotation: null,
            seat_size: null,
        }
        for (const t of Object.values(editingTables)) {
            for (const key of Object.keys(res)) {
                if (res[key] === null) res[key] = t[key];
                if (res[key] !== t[key]) res[key] = '';
            }
        }
        return res
    }, [editingTables]);

    if (!editingTables || Object.keys(editingTables).length === 0 || !selectedTablesRect) return;

    return (<div className='table-edit-window'
                 title={similarValues.name}
                 onClose={closeEdit}>
        <div className='table-name-wrapper'>
            <h3>{similarValues.name}</h3>
            <div className='delete-wrapper'>
                <img src={binImg} alt='delete' onClick={deleteSelectedTables}/>
            </div>
        </div>
        <p>Type: {similarValues.max_seating}-{similarValues.type}</p>
        <InputField
            title='Table name'
            value={similarValues.name}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {name: n}))}
        />
        {editingEvent && <InputField
            title='Max seated for this event'
            value={similarValues.active_seats}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {active_seats: n}))}
        />}
        {editingVenue && <InputField
            title='Max table seats'
            value={similarValues.max_seating}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {max_seating: n}))}
        />}
        {showWidth && <InputField
            title='Width'
            value={similarValues.width}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {width: n}))}
        />}
        {showLength && <InputField
            title='Length'
            value={similarValues.length}
            onChanged={n => editingTables.forEach(t => setTable(t.id, {length: n}))}
        />}

        <p className='rotation-handle-label'>Seat size</p>
        <label>{Math.floor(similarValues.seat_size * 100)} cm</label>
        <input className='rotation-handle' type='range' min={20} max={70}
               value={Math.floor(similarValues.seat_size * 100) || 45}
               onChange={v => editingTables.forEach(t => setTable(t.id, {seat_size: v.target.value / 100}))}/>


        <p className='rotation-handle-label'>Rotation</p>
        <label>{similarValues.rotation}</label>
        <input className='rotation-handle' type='range' min={-180} max={180} value={similarValues.rotation || 0}
               onChange={v => editingTables.forEach(t => setTable(t.id, {rotation: v.target.value}))}/>
    </div>)
}
